<script>

const payPeriod = [
  {
    value: 0,
    label: '1个月'
  },
  {
    value: 1,
    label: '3个月'
  },
  {
    value: 2,
    label: '半年'
  },
  {
    value: 3,
    label: '1年'
  },
  // {
  //   value: 4,
  //   label: '2年'
  // },
  // {
  //   value: 5,
  //   label: '3年'
  // }
]
export default
  {
    payPeriod
  }
</script>
