export const setDatas = (arr) => {
  const res = new Map()
  return arr.filter((arr) => !res.has(arr) && res.set(arr, 1));
}

export const setDatas1 = (arr) => {
  const res = new Map()

  sortData(arr, 'flag1')
  return arr.filter((arr) => !res.has(arr.flag1) && res.set(arr.flag1, 1));
}

// 对象排序:正序
export const sortData = (arr, key) => {
  return arr.sort(function (a, b) {
    var x = a[key]
    var y = b[key]
    return x - y
  })
}