<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>收费设置</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <!-- <div class="cards">
        <div class="title">
          开发者收入分成设置
          <el-button
            type="primary"
            plain
            class="buttons"
            @click="saveProportion"
            >保存</el-button
          >
        </div>

        <div class="text">
          设置时，只需输入平台的最小收入比例，个人开发者的收入比例自动计算。
        </div>
        <div class="divs">
          <div class="text1">
            平台收入比例：
            <el-input-number
              class="input"
              v-model="queryinfo.ratePlatform"
              :min="10"
              :max="100"
            ></el-input-number
            >%
          </div>
          <div class="span">（请输入10~100之间的整数）</div>
        </div>

        <div class="divs">
          <div class="text1">
            开发者收入比例：

            <el-input
              placeholder="请输入"
              v-model="queryinfo.rateUser"
              clearable
              class="input"
              disabled
            ></el-input
            >%
          </div>
          <div class="span">（自动计算）</div>
        </div>
      </div> -->

      <div class="cards">
        <div class="title">
          低代码工具收费设置
          <el-button type="primary" plain class="buttons" @click="savePrice"
            >保存</el-button
          >
        </div>
        <el-row class="tables">
          <el-table
            :data="PeriodList"
            :header-cell-style="{
              textAlign: 'center',
              background: '#FAFAFA',
              color: 'rgba(0, 0, 0, 0.85)',
              fontWeight: '500',
            }"
            :cell-style="{
              textAlign: 'center',
            }"
            tooltip-effect="dark"
          >
            <el-table-column min-width="100px">
              <template slot-scope="scope"> {{ scope.row.flag }}费用 </template>
            </el-table-column>
            <el-table-column
              v-for="(item, index) in versionList"
              :key="index"
              :label="item.packageKindName"
              min-width="200px"
            >
              <template slot-scope="scope">
                原始金额：
                <el-input-number
                  v-model="scope.row.flagPeriod[index].price"
                  :min="0"
                  :precision="2"
                  :disabled="scope.row.flagPeriod[index].flag"
                  size="medium"
                >
                </el-input-number>
                <div style="margin-top: 5px">
                  优惠金额：
                  <el-input-number
                    v-model="scope.row.flagPeriod[index].discount"
                    :min="0"
                    :precision="2"
                    size="medium"
                    :disabled="scope.row.flagPeriod[index].flag"
                  >
                  </el-input-number>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </div>
    </el-card>
  </div>
</template>
<script>
import { getPrice, batchUpdatePrice } from '@/api/toolmanager/toolVersion.js'
import { setProportion, getProportion } from '@/api/cloudmarket/finance'

import { payPeriodSet } from '@/libs/globalVar/computePeriod'
import { setDatas1 } from '@/libs/setData'

export default {
  data () {
    return {
      queryinfo: {
        ratePlatform: 0,
        rateUser: 0,
      },
      versionList: [],
      PeriodList: [],
      setPrice: []

    }
  },
  watch: {
    'queryinfo.ratePlatform': {
      handler (newVal) {
        this.queryinfo.rateUser = 100 - Number(newVal)
      }
    }
  },
  mounted () {
    this.getProportion()
    this.getList()
  },
  methods: {
    async getList () {
      const res = await getPrice()
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.versionList = res.data.data

      let PeriodList = []
      this.versionList.forEach(item => {
        let lists = []
        item.toolPriceDTOList.forEach(item1 => {
          lists.push(payPeriodSet(item1.payPeriod))
          PeriodList.push({ flag: payPeriodSet(item1.payPeriod), flag1: item1.payPeriod })
        })
        this.$set(item, 'PeriodList', lists)
      })

      this.PeriodList = setDatas1(PeriodList)

      this.PeriodList.forEach(item => {
        let ll = []
        this.versionList.forEach((item1, index) => {
          if (item1.PeriodList.includes(item.flag)) {
            ll.push({ flag: false, name: item1.packageKindName, price: '', discount: '', id: '' })
            item1.toolPriceDTOList.forEach(item11 => {
              if (Number(item11.payPeriod) === Number(item.flag1)) {
                ll[index].price = item11.price ? item11.price : ''
                ll[index].id = item11.id ? item11.id : ''
                ll[index].discount = item11.discount ? item11.discount : ''
              }
            })

          } else {
            ll.push({ flag: true, name: item1.packageKindName, price: '', discount: '', id: '' })
          }
        })
        this.$set(item, 'flagPeriod', ll)
      })
      console.log('9000', this.PeriodList, this.versionList)
    },

    async savePrice () {
      let submitList = []
      this.versionList.forEach((item, index) => {
        submitList.push({ packageKindName: item.packageKindName, packageKindId: item.packageKindId })
        let setList = []
        item.toolPriceDTOList.forEach(item1 => {
          this.PeriodList.forEach(item2 => {
            if (item2.flag1 === item1.payPeriod) {
              item2.flagPeriod.forEach(item3 => {
                if (!item3.flag && (item3.name === item.packageKindName)) {
                  this.$set(item1, 'price', item3.price)
                  this.$set(item1, 'id', item3.id)
                  this.$set(item1, 'discount', item3.discount)
                }
              })
            }
          })
          setList.push(item1)
        })
        this.$set(submitList[index], 'toolPriceDTOList', setList)
      });
      // console.log('00000', submitList)

      const res = await batchUpdatePrice(submitList)
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.$message.success('保存成功')
      this.getList()

    },

    async getProportion () {
      const res = await getProportion()
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.queryinfo.ratePlatform = res.data.data.platformDivide
    },
    async saveProportion () {
      const res = await setProportion(this.queryinfo.ratePlatform)
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.$message.success('保存成功')
    }
  }
}
</script>
<style lang="less" scoped>
.cards {
  text-align: left;
  margin-bottom: 20px;

  border: 1px solid rgb(200, 199, 199);

  .title {
    font-weight: 700;
    padding: 20px;
    margin-left: 200px;
    margin-bottom: 20px;
  }
  .text {
    margin-left: 100px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .divs {
    display: flex;
    margin-top: 20px;
  }
  .text1 {
    margin-left: 100px;
    text-align: right;
    width: 400px;
    margin-bottom: 20px;
  }
  /deep/ .input .el-input__inner {
    text-align: center;
  }
  .input {
    width: 200px;
    margin-right: 10px;
  }
  .span {
    color: grey;
    margin-left: 20px;
    margin-top: 10px;
  }

  .buttons {
    float: right;
    margin-right: 20px;
  }
  .tables {
    display: flex;
    margin-left: 40px;
    margin-right: 40px;
  }
}
</style>