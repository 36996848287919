import global_param from '@/libs/globalVar/global_param'

export const payPeriodSet = (para) => {
  let payPeriod = global_param.payPeriod
  let flags = Number(para) === 0
    ? payPeriod[0].label
    : Number(para) === 1
      ? payPeriod[1].label
      : Number(para) === 2
        ? payPeriod[2].label
        : Number(para) === 3
          ? payPeriod[3].label
          : Number(para) === 4
            ? payPeriod[4].label
            : payPeriod[5].label

  return flags
}